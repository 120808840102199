@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700&display=swap");

body {
    font-family: $font-family;
    background: $secondary;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

* {
    margin: 0px;
    padding: 0px;
    overflow-x: unset;
}

.cookie-bar {
    background-color: #1f1a11 !important;
    color: #ffffff !important;

    .cookie-btn {
        background: $primary !important;
        color: $white;
    }
}
