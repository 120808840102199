.show--settings {
    height: auto !important;
    overflow: hidden !important;
    -webkit-overflow-scrolling: touch !important;
}

.cc_div {
    font-size: 16px;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif !important;
}

:root {
    --cc-toggle-bg-on: $primary;
    --cc-btn-primary-bg: $primary;
    --cc-btn-primary-hover-bg: #017899;
    --cc-overlay-bg: rgba(4, 6, 8, 0.4);
}

#s-inr {
    @include regular();
}

#c-ttl,
#s-ttl {
    color: $primary !important;
    border-color: $primary !important;
    height: 40px;
    line-height: 40px;
    font-family: $font-family !important;
}

.b-tl,
.exp::before {
    color: $black !important;
    border-color: $black !important;
    @include font-size(13px);
    font-family: $font-family !important;
}

#c-s-bn {
    @include bg-brown-gradient();
    color: #fff;
    transition: all 0.2s ease-in-out !important;
    font-family: $font-family !important;

    &:hover {
        @include bg-brown-gradient();
        color: #fff;
    }
}

#cm .c_link {
    transition: all 0.2s ease-in-out !important;
}

#cm .c_link:hover,
#cm .c_link:active {
    @include bg-brown-gradient();
    color: #fff;
    transform: scale(1.1, 1.1);
}

.cc_div #c-bns {
    flex-direction: row-reverse;
    gap: 1em;
}

#s-c-bn {
    padding: 0;
    width: 1.7em;
    height: 1.7em;
    font-size: 1.45em;
    margin: 0;
    font-weight: initial;
    position: relative;
    background: #f5f5f5;
    font-family: $font-family !important;
}

#s-bl .c-bl.b-ex {
    padding: 0;
    border: none;
    background: #f5f5f5;
    transition: none;
    font-family: $font-family !important;
}

#s-cnt button + button:active,
#s-c-bn:active,
#s-c-bn:hover {
    background: #f5f5f5;
    font-family: $font-family !important;
}

#c-bns button + button {
    margin: 0;
    transition: all 0.2s ease-in-out !important;
}

.c--anim .c-bn {
    transition: all 0.2s ease-in-out !important;
}

#s-bns #s-rall-bn,
#s-bns #s-sv-bn,
#s-bns #s-all-bn {
    margin: 0;
}

#s-bns #s-all-bn {
    float: right;
    margin-left: 1em;
}

#c-bns button:first-child,
#s-bns button:first-child {
    color: #fff;
    @include bg-brown-gradient();
    font-family: $font-family !important;

    &:hover {
        @include bg-brown-gradient();
        color: #fff;
        transform: scale(1.1, 1.1);
    }
}

#s-sv-bn:hover,
.c-bn:hover {
    @include bg-brown-gradient();
}

#s-cnt .b-bn .b-tl:active,
#s-cnt .b-bn .b-tl:hover {
    background: #f5f5f5;
}

#cm,
#cm.cloud,
#cm.left,
#cm.right {
    border-radius: 12px;
    @include regular();
    padding: 1.6rem;
}

#c-txt {
    font-size: 0.8em;
    line-height: 1.6em;
    padding: 0rem;
    color: #000;
}

@media screen and (max-width: 688px) {
    .cc_div #c-bns {
        flex-direction: column-reverse;
        gap: 0;
    }

    .cc_div #c-bns #c-p-bn {
        margin-top: 0.5em;
    }

    .bns-t #s-bns {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        height: 11.5em;
        padding-bottom: 2em;
    }

    .cookie-close {
        width: 1.7em !important;
        height: 1.7em !important;
    }

    #s-bns #s-all-bn {
        margin: 0;
        order: 3;
    }
}

#s-bl .c-bl:hover {
    background: #f0f0f0;
}

#s-cnt button + button:active {
    transition: all 0.2s ease-in-out !important;
    @include bg-brown-gradient();
}

#s-c-bn:hover {
    box-shadow: unset !important;
}

.b-tl {
    transition: all 0.2s ease-in-out !important;
    &:hover {
        box-shadow: unset !important;
    }
}

#s-rall-bn {
    transition: all 0.2s ease-in-out !important;
    &:hover {
        background: #f0f0f0 !important;
        box-shadow: unset !important;
    }
}

#s-cnt .b-bn .b-tl {
    display: block;
    font-family: inherit;
    font-size: 0.85em;
    width: 100%;
    position: relative;
    padding: 1.3em 6.4em 1.3em 2.7em;
    background: none;
    transition: background-color 0.25s ease;
}

#s-cnt .p {
    font-size: 0.8em;
    line-height: 1.6em;
    margin-top: 0.85em;
    color: #000000;
}

.cc_div .cc-link {
    color: $black !important;
    border-bottom: 1px solid $black !important;
    display: inline;
    padding-bottom: 0;
    text-decoration: none;
    cursor: pointer;
}

.cc_div .bar #s-hdr,
.cc_div .bar #s-bl,
.cc_div .bar #s-bns {
    padding-left: 1em;
    padding-right: 1em;
}

.cc_div .b-tg .c-tgl {
    position: absolute;
    cursor: pointer;
    display: block;
    top: 0;
    left: 0;
    margin: 0;
    border: 0;
    background: #d5dee1;
}

#s-rall-bn,
#s-all-bn,
#s-sv-bn {
    background: #f5f5f5 !important;
    color: $black !important;
    box-shadow: unset !important;
}

#s-bns button:hover,
#s-bns button:active {
    background: #f5f5f5 !important;
    color: $black !important;
    box-shadow: unset !important;
}

.cc_div .title {
    padding: inherit !important;
    font-family: Arial, Helvetica, sans-serif !important;
}

.c_bl .title {
    padding: inherit !important;
    font-family: Arial, Helvetica, sans-serif !important;
}

#c-txt {
    font-size: 0.8em;
    line-height: 1.6em;
    padding: 0rem;
    color: #000;
    font-family: Arial, Helvetica, sans-serif !important;
}

#s-cnt .p {
    font-size: 0.8em;
    line-height: 1.6em;
    margin-top: 0.85em;
    color: #000000;
    font-family: Arial, Helvetica, sans-serif !important;
}

#s-bns #s-rall-bn,
#s-bns #s-sv-bn,
#s-bns #s-all-bn {
    font-family: Arial, Helvetica, sans-serif !important;
}

#s-cnt .b-bn .b-tl {
    font-family: Arial, Helvetica, sans-serif !important;
}

.cc_div .c-bn,
.cc_div .b-tl,
#s-ttl,
#c-ttl,
#s-bl td:before {
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif !important;
}
