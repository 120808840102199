.services {
    margin-top: 5vw;

    .title-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5vw;
        padding: 0 25px;
        h1 {
            font-family: $font-family-play;
            @include font-size(40px);
            color: $white;

            @include media-breakpoint-down(lg) {
                font-size: 24px;
            }
        }

        .dropdown {
            @include media-breakpoint-down(lg) {
                margin-top: 15px;
            }
        }

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            text-align: center;
        }
    }

    .massage {
        margin-bottom: 40px;
        padding: 0 25px;

        &__image {
            padding: 0;

            &:hover {
                -webkit-box-shadow: -2px -2px 53px -20px rgba($primary, 0.35);
                -moz-box-shadow: -2px -2px 53px -20px rgba($primary, 0.35);
                box-shadow: -2px -2px 53px -20px rgba($primary, 0.35);
                transition: color 0.15s ease-in-out,
                    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
                    border-radius 0.15s ease;
            }
            img {
                width: 100%;
                height: 450px;
                object-fit: cover;
                object-position: center center;

                @include media-breakpoint-down(lg) {
                    height: 60vw;
                }
            }
        }

        &__text {
            padding: 40px;
            background: $primaryBrown;
            display: flex;
            align-items: stretch;
            flex-direction: column;

            &:hover {
                -webkit-box-shadow: -2px -2px 53px -20px rgba($primary, 0.15);
                -moz-box-shadow: -2px -2px 53px -20px rgba($primary, 0.15);
                box-shadow: -2px -2px 53px -20px rgba($primary, 0.15);
                transition: color 0.15s ease-in-out,
                    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
                    border-radius 0.15s ease;
            }

            &--without-image {
                margin-right: 20px;
                margin-top: 20px;
                min-height: 500px;

                p {
                    min-height: 220px;

                    &.oxygen-paragraph {
                        min-height: 155px;
                    }
                }

                ul {
                    color: $white;
                    padding: 0 15px;

                    li {
                        margin: 0;
                        padding: 0;
                    }
                }

                span {
                    min-height: 80px;
                }
            }

            &--sale {
                min-height: 100px;
                span {
                    min-height: 30px;
                    margin-top: 0 !important;
                }
            }

            h3 {
                @include font-size(20px);
                font-family: $font-family-play;
                color: $white;
                margin-bottom: 3vw;
            }

            p {
                font-size: 14px;
                color: $white;
            }
            span {
                @include font-size(13px);
                color: $primary;
                letter-spacing: 2px;
                display: block;
                margin-top: 35px;
            }

            &-btn {
                width: 100%;
                display: flex;
                justify-content: end;
                margin-top: 40px;

                @include media-breakpoint-down(lg) {
                    justify-content: flex-start;
                }
            }
        }
    }
}
