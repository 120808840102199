// Body
$body-bg: #f8fafc;

//Fonts
@font-face {
    font-family: Avenir;
    src: url("../fonts/Avenir.otf");
}

@font-face {
    font-family: AvenirMedium;
    src: url("../fonts/Avenir-Book.otf");
}

@font-face {
    font-family: AvenirBlack;
    src: url("../fonts/Avenir-Black.otf");
}

// Typography
$font-family-sans-serif: "Avenir", sans-serif;
$font-family: "Avenir", sans-serif;
$font-family-play: "Playfair Display", serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

//Main colors
$secondary: #15120c;
$primaryBrown: #1f1a11;
$primary: #7c6544;
$icons: #3e3321;
$white: #fff;
$dropdown: #1f1a11;
$lightBrown: #4b3e29;
$body: #3e3321;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
