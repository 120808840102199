.header {
    background: url("./../../images/bg.png") no-repeat;
    background-size: cover;
    background-position: bottom center;
    padding: 0 100px;
    margin-bottom: 150px;
    position: relative;
    z-index: 10;
    height: 650px;

    h1 {
        font-family: $font-family-play;
        @include font-size(5rem);
        color: $white;
        position: relative;
        top: 160px;
        margin-bottom: 150px;
    }

    &__content {
        padding-left: 80px;
        position: relative;
    }
    .divider {
        width: 3px;
        height: 400px;
        background: $white;
        position: absolute;
        top: 40px;
        left: 30px;
    }

    @include media-breakpoint-down(lg) {
        padding: 0;
        background-size: contain;
        background-position: top center;
        height: auto;
        margin-bottom: auto;

        .divider {
            width: 3px;
            height: 230px;
            background: $white;
            position: absolute;
            top: 50px;
            left: 30px;
        }

        h1 {
            top: 120px;
            margin-bottom: 150px;
            @include font-size(3rem);
        }

        &__content {
            margin-top: 70px;
        }
    }

    // X-Large devices (large desktops, less than 1400px)
    @media (min-width: 1920px) {
        background-position: bottom center;
        height: 1200px;

        &__content {
            margin-top: 35%;
        }
    }
}

.contact-bar {
    margin-top: 10px;
    background: $primaryBrown;
    padding: 12px 25px 9px;
    color: $white;
    display: inline-block;
    text-decoration: none;
    position: relative;
    top: 150px;
    letter-spacing: 1.4px;
    font-size: 12px;

    svg {
        margin-right: 10px;

        .a {
            stroke: $white !important;
        }
    }

    &--light-brown {
        background: $lightBrown;

        &:hover {
            color: $white;
            -webkit-box-shadow: -2px -2px 53px -20px rgba($secondary, 0.75);
            -moz-box-shadow: -2px -2px 53px -20px rgba($secondary, 0.75);
            box-shadow: -2px -2px 53px -20px rgba($secondary, 0.75);
            background: lighten($color: $lightBrown, $amount: 2) !important;
            transition: color 0.15s ease-in-out,
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
                border-radius 0.15s ease;
        }
    }

    &:hover {
        color: $white;
        -webkit-box-shadow: -2px -2px 53px -20px rgba($secondary, 0.75);
        -moz-box-shadow: -2px -2px 53px -20px rgba($secondary, 0.75);
        box-shadow: -2px -2px 53px -20px rgba($secondary, 0.75);
        background: lighten($color: $primaryBrown, $amount: 2);
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
            border-radius 0.15s ease;
    }

    @include media-breakpoint-down(lg) {
        top: 90px;
        margin-top: 10px;
    }
}

.about-us {
    margin-top: 40px;
    position: relative;

    svg {
        position: absolute;
        left: -380px;
        top: -420px;
        .a {
            stroke: $lightBrown !important;
        }

        @include media-breakpoint-down(lg) {
            left: -140px;
            top: -480px;
        }
    }

    &__panel {
        padding: 10px;
        color: $white;
        z-index: 10;

        &--down {
            margin-top: 60px;
            display: flex;
            align-items: flex-end;
            flex-direction: column;

            @include media-breakpoint-down(lg) {
                flex-direction: row;
            }
        }

        h3 {
            text-align: right;
            @include font-size(0.8rem);
            line-height: 1.7rem;
            margin-bottom: 30px;

            @include media-breakpoint-down(lg) {
                @include font-size(1rem);
            }
        }

        h2 {
            text-align: left;
            @include font-size(1.8rem);
            font-family: $font-family-play;
            font-weight: normal;
            line-height: 2.6rem;

            @include media-breakpoint-down(lg) {
                @include font-size(1.8rem);
                line-height: 2.2rem;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        margin-top: 0px;
        padding: 0 25px;
    }
}

.service {
    z-index: 10;
    margin: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;

    &--up {
        position: relative;
        top: -80px;

        @include media-breakpoint-down(lg) {
            top: 0px;
        }
    }

    @include media-breakpoint-down(lg) {
        top: 0px;
        margin: 0px;
        font-size: 14px;
        padding: 3px;
    }

    &__number {
        font-size: 4rem;
        font-family: $font-family-play;
        color: $white;
        width: 100px;
        height: 100px;
        border-radius: 80px;
        line-height: 80px;
        display: flex;
        align-items: baseline;
        justify-content: center;
        background: rgba($lightBrown, 0.8);
        position: relative;
        top: 50px;
        z-index: 30;

        @include media-breakpoint-down(lg) {
            width: 20vw;
            height: 20vw;
            line-height: 18vw;
            top: 10vw;
            font-size: 10vw;
        }
    }

    &__image {
        display: flex;
        align-items: center;
        flex-direction: column;
        background-image: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0),
                rgba($secondary, 1)
            ),
            url("../../images/photos/masaz-min.jpg");
        background-position: top center;
        background-size: cover;
        width: 260px;
        height: 350px;
        position: relative;

        &:hover {
            -webkit-box-shadow: 0px -26px 65px -19px rgba($lightBrown, 0.55);
            -moz-box-shadow: 0px -26px 65px -19px rgba($lightBrown, 0.55);
            box-shadow: 0px -26px 65px -19px rgba($lightBrown, 0.55);
            transition: color 0.15s ease-in-out,
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
                border-radius 0.15s ease, transform 0.25s ease-in-out;

            transform: scale(1.1);
        }

        span {
            bottom: 20px;
            position: absolute;
            color: $white;
            text-transform: uppercase;
            padding: 0 5px;
            letter-spacing: 3.1px;
            font-size: 12px;

            img {
                width: 20px;
                height: auto;
                margin-bottom: 5px;
                margin-left: 10px;
                font-weight: 200;

                @include media-breakpoint-down(lg) {
                    width: 10px;
                    display: none;
                }
            }
        }

        @include media-breakpoint-down(lg) {
            width: 100%;
            height: 50vw;
        }

        &--second {
            background-image: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 0),
                    rgba($secondary, 1)
                ),
                url("../../images/photos/lymfodrenaz-min.jpg");
        }

        &--third {
            background-image: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 0),
                    rgba($secondary, 1)
                ),
                url("../../images/photos/Tehotenskamasaz-min.jpg");
        }

        &--fourth {
            background-image: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 0),
                    rgba($secondary, 1)
                ),
                url("../../images/photos/masaz3-min.jpg");
        }
    }
}

.our-team {
    position: relative;
    background: url("../../images/Ellipse14.svg") no-repeat;
    background-position-x: 680px;
    color: $white;
    padding-top: 50px;
    padding-bottom: 190px;

    @include media-breakpoint-down(lg) {
        padding-bottom: 30px;
    }

    .pattern {
        background: url("../../images/Group4.svg") repeat-x;
        background-size: cover;
        margin: 25px 0 80px;
        width: 100%;
        height: 30px;
        animation: animatedBackground 500s linear infinite;

        @include media-breakpoint-down(lg) {
            margin: 30px 0 80px;
            height: 30px;
        }
    }

    @keyframes animatedBackground {
        from {
            background-position: 0 0;
        }
        /*use negative width if you want it to flow right to left else and positive for left to right*/
        to {
            background-position: 10000px 0;
        }
    }

    .info {
        margin: 40px 0 80px 0;
        background: $dropdown;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-family-play;
        padding: 50px 25px;
        @include font-size(24px);

        a {
            text-decoration: none;
        }
    }

    &__member {
        margin-top: 60px;
        padding: 20px;

        @include media-breakpoint-down(lg) {
            margin-top: 30px;
        }

        h5 {
            font-family: $font-family-play;
            @include font-size(20px);
        }

        span {
            color: $primary;
            @include font-size(12px);
            letter-spacing: 2px;
            margin-bottom: 20px;
            display: block;
        }
    }
}

.title {
    padding: 20px;
    h2,
    h4 {
        @include font-size(14px);
        color: $primary;
        letter-spacing: 2.8px;

        &:before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-right: 10px;
            margin-left: 10px;
            background: $primary;
        }
    }
}

.title-center {
    margin-bottom: 40px;
    h6 {
        font-family: $font-family-play;
        @include font-size(40px);
        padding: 0;
        margin-bottom: 10px;
        color: $white;
    }
    span {
        @include font-size(11px);
        text-transform: uppercase;
        color: $white;
        letter-spacing: 3px;
    }
}

.references {
    margin-bottom: 80px;

    &__item {
        display: flex !important;
        padding: 40px;
        background: $primaryBrown;
        align-items: center;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }

        img {
            display: inline-block;
            margin-right: 35px;

            @include media-breakpoint-down(lg) {
                width: 30px;
            }
        }

        p {
            color: $white;
            display: inline-block;
            padding: 0;
            margin: 0;

            span {
                display: block;
                margin-top: 30px;
                font-style: italic;
                font-weight: 600;
            }
        }
    }

    .slick-dots li button::before {
        position: absolute;
        top: 0px;
        left: 0px;
        content: "•";
        width: 30px;
        height: 30px;
        font-family: slick;
        font-size: 10px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 1;
        -webkit-font-smoothing: antialiased;
    }

    .slick-dots li.slick-active button:before {
        color: $white;
        opacity: 1;
    }
}

.faq {
    margin-bottom: 80px;
    .accordion-item {
        border-bottom: 1px solid $lightBrown;
    }
    .accordion-button {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 1.5rem 1.25rem;
        font-size: 0.9rem;
        color: $white;
        text-align: left;
        background-color: $secondary !important;
        border: 0;
        border-radius: 0;
        overflow-anchor: none;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
            border-radius 0.15s ease;
    }

    .accordion-body {
        background: $secondary;
        color: $white;
    }

    .accordion-button::after {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: auto;
        content: "";
        background-image: url("../../images/up-arrow-key.svg");
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform 0.2s ease-in-out;
    }

    .accordion-item:last-of-type .accordion-button.collapsed {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.contact {
    background: $primaryBrown;
    padding: 60px;
    color: $white;

    @include media-breakpoint-down(lg) {
        padding: 25px;
    }

    &__happy {
        padding-right: 50px;

        @include media-breakpoint-down(lg) {
            padding-right: 25px;
        }

        h6 {
            font-family: $font-family-play;
            @include font-size(40px);
            line-height: 60px;

            @include media-breakpoint-down(lg) {
                line-height: 30px;
                margin-bottom: 30px;
                font-size: 24px;
            }
        }
    }

    &__info {
        @include font-size(11px);
        img {
            margin-right: 10px;
        }
    }

    &__social {
        a {
            margin-right: 15px;
        }
    }
}

.footer {
    color: $lightBrown;
    padding: 30px;

    &__text {
        display: flex;
        justify-content: space-between;
        @include media-breakpoint-down(lg) {
            flex-direction: column;

            span {
                margin-top: 30px;
            }
        }
    }
}
