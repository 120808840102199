//Gradients

@mixin bg-brown-gradient($deg: 90deg) {
    background: transparent linear-gradient($deg, #4b3e29 0%, #1f1a11 100%) 0%
        0% no-repeat;
    box-shadow: 0px 15px 30px #807e7d;
}

//Fonts weight
@mixin semi-bold() {
    font-family: $font-family-sans-serif;
    font-weight: 600;
}

@mixin bold() {
    font-family: $font-family-sans-serif;
    font-weight: 700;
}

@mixin medium() {
    font-family: $font-family-sans-serif;
    font-weight: 500;
}

@mixin regular() {
    font-family: $font-family-sans-serif;
    font-weight: 400;
}

@mixin black() {
    font-family: $font-family-sans-serif;
    font-weight: 800;
}
