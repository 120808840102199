// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";
@import "mixins";

// Bootstrap
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons";

@import "../../node_modules/rfs/scss.scss";
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/slick-carousel/slick/slick-theme.scss";
@import "./../../node_modules/vanilla-cookieconsent/src/cookieconsent.css";
//@import "../../node_modules/aos/src/sass/aos.scss";

//Partials
@import "frontend/partials/index";

//Pages
@import "frontend/homepage.scss";
@import "frontend/services.scss";
