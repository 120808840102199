.btn {
    font-family: $font-family-sans-serif;
    &-primary {
        border-radius: 0;
        border: 0px;
        color: $white;
        padding: 10px 20px 7px;
        background: $lightBrown;
        font-weight: 200;
    }

    &-filter {
        border: 1px solid #fff;
        background: transparent;
        color: $white;
        border-radius: 0px;
        padding: 8px 20px;
        @include font-size(14px);

        &:hover {
            color: $white;
        }
    }

    &-order {
        background: $primary;
        color: $white;
        border: 0px;
        border-radius: 0;
        padding: 12px 25px 10px;
        letter-spacing: 3px;
        @include font-size(13px);

        &:hover {
            color: $white;
            -webkit-box-shadow: -2px -2px 53px -20px rgba($secondary, 0.75);
            -moz-box-shadow: -2px -2px 53px -20px rgba($secondary, 0.75);
            box-shadow: -2px -2px 53px -20px rgba($secondary, 0.75);
            background: lighten($color: $lightBrown, $amount: 2) !important;
            transition: color 0.15s ease-in-out,
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
                border-radius 0.15s ease;
        }
    }
}
