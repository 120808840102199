.bg-dark {
    background: $secondary !important;
    text-transform: uppercase;
    padding: 0;
}

.navbar-dark .navbar-nav .nav-link {
    color: $white !important;
    border-bottom: 3px solid transparent;
    padding: 1.6rem 1.5rem 1.4rem;
    letter-spacing: 1.2px;
    font-size: 13px;

    &:hover {
        border-bottom: 3px solid $primary;
    }
}
.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 12rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 0.9rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid rgba(0, 0, 0, 0.15);
    border-radius: 0rem;
    background: $dropdown;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    top: 68px !important;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.55);
    border-color: rgba(255, 255, 255, 0.1);
    margin: 0px;
    padding: 0;
}

.dropdown-item {
    font-size: rem-calc(11);
    color: $white;
    background: transparent;
    text-transform: uppercase;
    padding: 0.5rem 1rem;

    &:hover {
        display: block;
        width: 100%;
        clear: both;
        font-weight: 400;
        color: $white;
        text-align: inherit;
        text-decoration: none;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        background: lighten($dropdown, $amount: 5);
    }
}

@include media-breakpoint-down(lg) {
    .navbar-dark .navbar-nav .nav-link.login:hover,
    .navbar-dark .navbar-nav .nav-link.login:active,
    .navbar-dark .navbar-nav .nav-link.login:focus {
        padding: 5px 20px 4px;
    }

    .navbar-dark .navbar-nav .active > .nav-link {
        background: #79c724 !important;
        color: #fff !important;
        border-radius: 20px;
        padding: 10px 20px 10px;
        margin: 10px;
    }

    .navbar-dark .navbar-nav .nav-link {
        color: #4a4a4a;
        font-size: 13px;
        font-weight: 100;
        padding: 10px 20px 10px;
        text-transform: uppercase;
        margin: 5px;
    }

    .navbar {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    }

    .navbar-nav {
        margin: 40px 0;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: initial !important;
        top: 60px !important;
    }
}

.collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.125rem;
    line-height: 1;
    background-color: transparent;
    border: 0px solid transparent;
    border-radius: 0;
    transition: box-shadow 0.15s ease-in-out;
}
